import { Routes, Route } from "react-router-dom";
import Layout from './components/layout';
import Home from './pages/Home';
import NotFound from "./pages/NotFound";

function App() {
    return (
    <Layout>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<NotFound />} />
        </Routes> 
    </Layout>
    );
}

export default App;
